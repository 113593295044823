$(document).ready(function() {
  var an = null;
  $('#fullpage').fullpage({
    anchors: [ 'welcome-page', 'web-developer', 'software-engineer', 'marketing-expertise', 'multimedia-ready', 'management-capable', 'design-knowledge', 'contact-me' ],
    //options
    autoScrolling: true,
    //scrollHorizontally: true,
    beforeLeave: function( origin, destination, direction, trigger ){
      //console.log( 'Leaving' )
    },
    onLeave: function( origin, destination, direction, trigger ){
      var leavingSection = this;
      //console.log( 'leaving', origin, 'for', destination );
      //console.log( leavingSection.find( '.section-content-wrap' ).length );
      an = leavingSection.find( '.section-content-wrap' ).css( 'animation-name' );
      leavingSection.find( '.section-content-wrap' ).css( 'animation-name', '' );
      if( destination == 1 ){
        //console.log('back21')

        $('#signature').removeClass('animate__swing')
        $('#welcome .description')
            .removeClass('animate__slideInUp animate__rubberBand')

        setTimeout( function(){
          $('#signature')
            .addClass('animate__animated animate__swing');
        }, 1200 );

        setTimeout( function(){
          $('#welcome .description')
            .addClass('animate__rubberBand');
        }, 2800 );
      }
    },
    afterSlideLoad: function( section, origin, destination, direction, trigger ){
      var loadedSlide = this;
      loadedSlide.find( '.section-content-wrap' ).css( 'animation-name', an );
      //console.log( 'arrived at', destination );

    }
  });

  //methods
  $.fn.fullpage.setAllowScrolling(true);

});


require(['https://cdn.jsdelivr.net/npm/sketch-js@1.1.3/js/sketch.min.js'], function( Sketch ){

  //console.log( 'starting magic', Sketch );

  function Particle( x, y, radius ) {
    this.init( x, y, radius );
  }

  Particle.prototype = {

    init: function( x, y, radius ) {

      this.alive = true;

      this.radius = radius || 2;
      this.wander = 2.8;
      this.theta = random( TWO_PI );
      this.drag = 3.49;
      this.color = '#FFBC15';

      this.x = x || 0.0;
      this.y = y || 0.0;

      this.vx = 0.0;
      this.vy = 0.0;

    },

    move: function() {

      this.x += this.vx;
      this.y += this.vy;

      this.vx *= this.drag;
      this.vy *= this.drag;

      this.theta += random( -0.5, 0.5 ) * this.wander;
      this.vx += sin( this.theta ) * 0.1;
      this.vy += cos( this.theta ) * 0.1;

      this.radius *= 0.98;  //.96 = best
      this.alive = this.radius > 0.5;

    },

    draw: function( ctx ) {

      ctx.beginPath();
      ctx.arc( this.x, this.y, this.radius, 0, TWO_PI );
      ctx.fillStyle = this.color;
      ctx.fill();
    }

  };

  // ----------------------------------------
  // Example
  // ----------------------------------------

  var MAX_PARTICLES = 7000;
  //var COLOURS = [ '#ffbc15', '#0043ea' ]; //, '#e3e3e3', '#0e0e0e' ];

  var COLOURS = [

    '#FFBC15', // (Gold)
    '#FFE315',
    '#FF9415',

    '#1558FF', // (Blue)
    '#157EFF',
    '#1530FF',

    //'#DCDCDC',
    //'#323232',
    //'#8A8A8A',

    '#FF151D',
    '#B22CFF',
    '#FF2CAD',

    '#FFBC15', // (Gold)
    '#FFBC15', // (Gold)


   ]

  var particles = [];
  var pool = [];

  var demo = Sketch.create({
    container: document.getElementById( 'contact-magic' )
  });

  //console.log( 'Found', document.getElementById( 'contact-magic' ) );

  demo.setup = function() {

    // Set off some initial particles.
    var i, x, y;

    for ( i = 0; i < 0; i++ ) {
      x = ( demo.width * 0.5 ) + random( -100, 100 );
      y = ( demo.height * 0.5 ) + random( -100, 100 );
      demo.spawn( x, y );
    }

  };

  demo.spawn = function( x, y ) {

    if ( particles.length >= MAX_PARTICLES )
      pool.push( particles.shift() );

    particle = pool.length ? pool.pop() : new Particle();
    particle.init( x, y, random( 2, 9 ) );

    particle.wander = random( 0.2, .80 );
    particle.color = random( COLOURS );
    particle.drag = random( 0.3, 0.8 );

    //console.log( particle.color )

    theta = random( TWO_PI );
    force = random( 3, 14 );

    particle.vx = sin( theta ) * force;
    particle.vy = cos( theta ) * force;

    particles.push( particle );

  };

  demo.update = function() {

    var i, particle;

    for ( i = particles.length - 1; i >= 0; i-- ) {

      particle = particles[i];

      if ( particle.alive ) particle.move();
      else pool.push( particles.splice( i, 1 )[0] );

    }

  };

  demo.draw = function() {

    demo.globalCompositeOperation  = 'source-over';

    for ( var i = particles.length - 1; i >= 0; i-- ) {
      particles[i].draw( demo );
    }

  };

  demo.mousemove = function() {

    //console.log( 'moving mouse' );

    var particle, theta, force, touch, max, i, j, n;

    for ( i = 0, n = demo.touches.length; i < n; i++ ) {
      touch = demo.touches[i], max = random( 1, 2 );
      for ( j = 0; j < max; j++ ) demo.spawn( touch.x, touch.y );
    }

  };

});
